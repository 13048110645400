.pro-sidebar .pro-menu a:hover {
  color: #b3c535;
}
.active-menu {
  color: #b3c535;
}
.active-menu {
  color: #b3c535;
  border-left: solid 2px #b3c535;
}
.active-menu-link {
  color: #b3c535 !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: #b3c535;
}

.pro-sidebar {
  position: relative;
  z-index: 40 !important;
}
